import { extendTheme } from "@chakra-ui/react"
// import "@fontsource/gloria-hallelujah";
import "@fontsource/montserrat";
import "@fontsource/tangerine";

const theme = {
  fonts: {
    body: "Montserrat",
    heading: "Tangerine",
  },
}
export default extendTheme(theme)